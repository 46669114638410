import { IStoryTelling } from 'components/StoryTelling/models';
import React, { FC } from 'react';
import LazyLoad from 'react-lazyload';
import { parseBoolean } from 'utils/parseHelpers';

const LazyAnimation: FC<IStoryTelling> = ({ animation, autoPlay }) => {
  const autoPlayAnimation = parseBoolean(autoPlay);

  return (
    <LazyLoad width="100%">
      <video
        width="100%"
        loop
        playsInline
        muted
        autoPlay={!autoPlayAnimation}
        controls={autoPlayAnimation}
      >
        <source src={animation.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </LazyLoad>
  );
};

export default LazyAnimation;
